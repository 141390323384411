<template>
  <section class="news-section">
    <div class="news-section__container" :class="{ 'news-section__container--hide': !announces.length }">
      <div class="news-section__content news-section__content--left">
        <div class="news-section__header">
          <h2 class="title">Новости</h2>
          <router-link :to="{ name: 'press-list', query: { type: 1 } }" class="all-button">
            <span>Все новости</span>
            <IconComponent category="vuesax-bold" name="arrow-right" />
          </router-link>
        </div>
        <div class="news-section__left" :class="{ 'news-section__left--full': !announces.length }">
          <NewsItem v-for="(article, i) in formatNews" :key="i" :data="article" />
        </div>
      </div>
      <div class="news-section__content news-section__content--right" v-if="announces.length">
        <div class="news-section__header">
          <h2 v-if="$store.state.department.system_type.code !== 5" class="title">Анонсы</h2>
          <h2 v-else class="title">Помогли</h2>
          <router-link :to="{ name: 'press-list', query: { type: 2 } }" class="all-button">
            <span v-if="$store.state.department.system_type.code !== 5">Все анонсы</span>
            <span v-else>Смотреть всё</span>
            <IconComponent category="vuesax-bold" name="arrow-right" />
          </router-link>
        </div>
        <div class="news-section__right">
          <AnnounceItem :data="announce" v-for="(announce, i) in announces" :key="i" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import NewsItem from "./NewsItem.vue";
import AnnounceItem from "./AnnounceItem.vue";

export default {
  name: "NewsSection",
  computed: {
    announces() {
      return this.$store.state.home.announces.data;
    },
    news() {
      return this.$store.state.home.news.data;
    },
    formatNews() {
      return !this.announces.length ? this.news : this.news.slice(0, 4);
    },
  },
  components: { AnnounceItem, NewsItem, IconComponent },
};
</script>

<style lang="stylus">
.news-section {
  display flex
  align-items flex-start
  justify-content center
  padding 0 30px
  +below(1280px) {
    padding 0
  }

  &__container {
    max-width var(--max-width)
    width 100%
    display grid
    align-items start
    grid-template-columns 8fr 4fr
    grid-gap 30px
    +below(1280px) {
      grid-template-columns 1fr
    }
    &--hide {
      grid-template-columns none
    }
  }

  &__content {
    display grid
    align-items start
    grid-gap 40px
    +below(1280px) {
      &--left {
        & ^[0]__header {
          padding 0 30px
        }
      }

      &--right {
        padding 0 30px
      }
    }
  }

  &__header {
    display flex
    gap 5px
    align-items center
    flex-wrap wrap
    justify-content space-between
  }

  &__left {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 10px
    &--full {
      grid-template-columns 1fr 1fr 1fr
    }
    +below(1280px) {
      display flex
      align-items center
      gap 30px
      overflow-y auto
      padding 0 30px 15px
    }
  }

  &__right {
    background var(--white)
    border-radius 5px
    display: grid;
  }
}
</style>
